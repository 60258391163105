import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label
} from "reactstrap";

import NotificationAlert from "react-notification-alert";

import defaultService from "../../services/defaultService";
import Avatar from "components/Avatar";

const table = "socios";
function PerfilScreen({ nameRoute }) {

  const [currentItem, setCurrentItem] = useState({});
  const notificationAlert = React.useRef();

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    const localUser_ = JSON.parse(localStorage?.getItem("user"));

    setCurrentItem(localUser_);
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    const form = {};

    form.table = table;

    for (let index = 0; index < e.target.length; index++) {
      if (e.target[index].name)
        form[e.target[index].name] = e.target[index].value;
    }

    if (currentItem?.id) {
      editItem(form);
      return;
    }

  }

  const editItem = async (userData) => {

    const resp = await defaultService.update(currentItem?.id, userData);
    resp?.status == 200 ?
      notify("tr", 2, "Atualizado com sucesso!")
      : notify("tr", 3, 'Erro ao atalizar!');

    delete userData?.table;

    setCurrentItem(userData);
    localStorage.setItem("user", JSON.stringify(userData));

    onLoad();
  }

  const notify = (place, color, message) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div><div>{message}</div></div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
      <div className="content">
        <NotificationAlert ref={notificationAlert} />
        <Row>
          <Col md="12">
            <Card className="card-user m-3" >
              <CardHeader>
                <CardTitle tag="h5">Seus dados:</CardTitle>
              </CardHeader>
              <CardBody>
                <Form onSubmit={onSubmit} datatype="multipart/form-data">
                  <Input type="hidden" name="id" defaultValue={currentItem?.id} />

                  <Row>
                    <Col className="pr-1" md="4">
                      <Avatar />
                    </Col>

                    <Col className="" md="8">
                      <FormGroup>
                        <label>Nome</label>
                        <Input
                          defaultValue={currentItem?.nome}
                          type="text"
                          name="nome"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          defaultValue={currentItem?.email}
                          type="email"
                          name="email"
                        />
                      </FormGroup>
                    </Col>

                    {/*<Col className="pr-1" md="12">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Foto
                        </label>
                        <Input
                          defaultValue={currentItem?.foto}
                          type="file"
                          name="foto"
                        />
                      </FormGroup>
                    </Col>*/}

                    <Col md="6">
                      <FormGroup>
                        <label>CPF</label>
                        <Input
                          defaultValue={currentItem?.cpf}
                          placeholder=""
                          type="text"
                          name="cpf"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>RG</label>
                        <Input
                          defaultValue={currentItem?.rg}
                          placeholder=""
                          type="text"
                          name="rg"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>Telefone</label>
                        <Input
                          defaultValue={currentItem?.telefone}
                          placeholder=""
                          type="text"
                          name="telefone"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>Telefone para contato</label>
                        <Input
                          defaultValue={currentItem?.telefone_contato}
                          placeholder=""
                          type="text"
                          name="telefone_contato"
                        />
                      </FormGroup>
                    </Col>
                    <input type="hidden" name="username" defaultValue="" />
                    <Col md="6">
                      <FormGroup>
                        <label>Senha</label>
                        <Input
                          defaultValue={currentItem?.senha}
                          placeholder=""
                          type="text"
                          name="senha"
                        />
                      </FormGroup>
                    </Col>


                    <Col md="6">
                      <FormGroup>
                        <Label >Tipo de Acesso</Label>
                        <Input
                          disabled
                          type="select"
                          name="tipo_acesso"
                          defaultValue={currentItem?.tipo_acesso}>
                          <option selected={currentItem?.tipo_acesso == "Admin"} value="Admin">Admin</option>
                          <option selected={currentItem?.tipo_acesso == "Sindicalizado"} value="Sindicalizado">Sindicalizado</option>
                          <option selected={currentItem?.tipo_acesso == "Funcionário"} value="Funcionario">Funcionário</option>
                          <option selected={currentItem?.tipo_acesso == "Financeiro"} value="Financeiro">Financeiro</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <input type="hidden" name="numero_socio" defaultValue="" />
                    <Col md="6">
                      <FormGroup>
                        <Label >Adimplente</Label>
                        <Input
                          disabled
                          type="select"
                          name="adimplente"
                          defaultValue={currentItem?.adimplente}>
                          <option value="Sim">Sim</option>
                          <option value="Nao">Não</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Atualizar
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PerfilScreen;
