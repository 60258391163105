import api from "./api";

// Definindo o serviço do usuário
const defaultService = {

  list: async (table) => {
    try {
      const response = await api.get(`/default/list/all/${table}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao listar itens:', error);
      throw error;
    }
  },

  listSelect: async (table) => {
    try {
      const response = await api.get(`/default/list/select/${table}?hand=${Math.random()}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao listar itens:', error);
      throw error;
    }
  },

  search: async (partialName) => {
    try {
      const response = await api.get(`/default/search/${partialName}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao listar itens:', error);
      throw error;
    }
  },

  // Atualizar um usuário existente
  update: async (id, data) => {
    try {
      const response = await api.post(`/default/update/${id}`, data);
      return response;
    } catch (error) {
      console.error('Erro ao atualizar item:', error);
      throw error;
    }
  },

  updateAvatar: async (id, data) => {
    try {
      const response = await api.post(`/default/updateAvatar/${id}`, data);
      console.log(response);
      return response;
    } catch (error) {
      console.error('Erro ao atualizar item:', error);
      throw error;
    }
  },

  // Criar um novo usuário
  create: async (data) => {
    try {
      const response = await api.post(`/default/register`, data);
      return response;
    } catch (error) {
      console.error('Erro ao criar item:', error);
      throw error;
    }
  },

  // Deletar um usuário existente
  delete: async (id, table) => {
    try {
      const response = await api.post(`/default/delete/${id}/${table}`);
      return response;
    } catch (error) {
      console.error('Erro ao deletar item:', error);
      throw error;
    }
  },
  recuperarSenha : async (email) => {
    try {
      const response = await api.post('https://app.sinpolsergipe.org.br/api/solicitar_recuperacao.php' , email);
      return response;
    } catch (error) {
      console.error('Erro:', error);
      throw error;
    }
  }
};

export default defaultService;