import Carteira from "components/Carteira";
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
} from "reactstrap";
import defaultService from "services/defaultService";
import VerAssembleiasScreen from "./VerAssembleiasScreen";
import VerNoticiasScreen from "./VerNoticiasScreen";

function Dashboard() {

  const [socios, setSocios] = useState([]);
  const localUser = JSON.parse(localStorage?.getItem("user"));

  useEffect(() => {
    listItems();
  }, []);

  const listItems = async () => {

    var items_ = await defaultService.listSelect("socios");
    items_?.[0]?.id && setSocios(items_);
  }

  return (
    <>
      <div className="content">
        <Col>

          <Row>
            <Col lg="10" md="10" sm="12" >
              <p className="navbar-brand mt-4">Seja bem vindo, {localUser?.name}</p>

              <p className="card-category">Minha carteira</p>
              <Carteira
                {...{
                  nome: socios?.find(socio => socio.id == localUser?.id)?.nome,
                  cpf: socios?.find(socio => socio.id == localUser?.id)?.cpf,
                  rg: socios?.find(socio => socio.id == localUser?.id)?.rg,
                  numero_socio: socios?.find(socio => socio.id == localUser?.id)?.numero_socio,
                  telefone: socios?.find(socio => socio.id == localUser?.id)?.telefone,
                  birthdate: "01/01/2000",
                  expiryDate: "01/01/2025",
                  avatar: localUser?.avatar
                }} />
            </Col>

          </Row>


          <p className="card-category ml-3 mt-5">Próximas reuniões</p>
          <Col lg="12" md="12" sm="12" style={{ height: "280px", marginBottom: 10, overflow: "auto" }} >
            <VerAssembleiasScreen />
          </Col>
          <Col className="mb-5" lg="12" md="12" sm="12" style={{ alignItems: "center", alignSelf: "center" }}>
            <Button color="primary" style={{ margin: 'auto', alignSelf: "center", }} size="sm" type="link" href="reunioes" >Ver todos</Button>
          </Col>


          <p className="card-category ml-3 mt-5">Próximos notícias</p>
          <Col lg="12" md="12" sm="12" style={{ height: "300px", marginBottom: 30, overflow: "auto" }} >
            <VerNoticiasScreen />
          </Col>
          <Col className="mb-5" lg="12" md="12" sm="12" style={{ alignItems: "center", alignSelf: "center" }}>
            <Button color="primary" style={{ margin: 'auto', alignSelf: "center", }} size="sm" type="link" href="news" >Ver todos</Button>
          </Col>

          {/*<Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-globe text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Capacitys</p>
                      <CardTitle tag="p">150GB</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Update Now
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Revenue</p>
                      <CardTitle tag="p">$ 1,345</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="far fa-calendar" /> Last day
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-vector text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Errors</p>
                      <CardTitle tag="p">23</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="far fa-clock" /> In the last hour
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Followers</p>
                      <CardTitle tag="p">+45K</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Update now
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Users Behavior</CardTitle>
                <p className="card-category">24 Hours performance</p>
              </CardHeader>
              <CardBody>
                <Line
                  data={dashboard24HoursPerformanceChart.data}
                  options={dashboard24HoursPerformanceChart.options}
                  width={400}
                  height={100}
                />
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-history" /> Updated 3 minutes ago
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Email Statistics</CardTitle>
                <p className="card-category">Last Campaign Performance</p>
              </CardHeader>
              <CardBody style={{ height: "266px" }}>
                <Pie
                  data={dashboardEmailStatisticsChart.data}
                  options={dashboardEmailStatisticsChart.options}
                />
              </CardBody>
              <CardFooter>
                <div className="legend">
                  <i className="fa fa-circle text-primary" /> Opened{" "}
                  <i className="fa fa-circle text-warning" /> Read{" "}
                  <i className="fa fa-circle text-danger" /> Deleted{" "}
                  <i className="fa fa-circle text-gray" /> Unopened
                </div>
                <hr />
                <div className="stats">
                  <i className="fa fa-calendar" /> Number of emails sent
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col md="8">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h5">NASDAQ: AAPL</CardTitle>
                <p className="card-category">Line Chart with Points</p>
              </CardHeader>
              <CardBody>
                <Line
                  data={dashboardNASDAQChart.data}
                  options={dashboardNASDAQChart.options}
                  width={400}
                  height={100}
                />
              </CardBody>
              <CardFooter>
                <div className="chart-legend">
                  <i className="fa fa-circle text-info" /> Tesla Model S{" "}
                  <i className="fa fa-circle text-warning" /> BMW 5 Series
                </div>
                <hr />
                <div className="card-stats">
                  <i className="fa fa-check" /> Data information certified
                </div>
              </CardFooter>
            </Card>
          </Col>*/}
        </Col>
      </div>
    </>
  );
}

export default Dashboard;
