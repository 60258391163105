import React, { useState } from 'react';
import { Card, CardBody, CardTitle, CardText, CardImg, Row, Col } from 'reactstrap';

const Carteira = (props) => {

    const user = JSON.parse(localStorage.getItem("user"));
    const [image, setImage] = useState(props?.avatar);

    return (
        <Card style={{ backgroundColor: 'lightgrey' }}>
            <Col>
                <Row>
                    <CardImg top
                        className='ml-3 mt-4 mb-4'
                        style={{ width: 120, height: 120, border: 5 }}
                        src={image ? 'https://app.sinpolsergipe.org.br/api/uploads/avatars/' + image : require("assets/img/default-avatar.png")}
                        alt="QR Code" />
                    <Col md="7" className='mt-5 ml-1'>
                        <CardTitle>
                            <strong>{props.nome}</strong>
                        </CardTitle>
                        <CardText>
                            <strong>CPF:</strong> {props.cpf} <br />
                            <strong>RG:</strong> {props.rg} <br />
                            <strong>Telefone:</strong> {props.telefone}
                        </CardText>
                    </Col>

                </Row>

                <Row>
                    <CardImg className='ml-3 mt-4 mb-4' style={{ width: 130, height: 130, border: 5 }} src={require("assets/img/codeqr.jpg")} />
                    <Col md="7" className='mt-4'>
                        <CardText>
                            <strong>Número de sócio:</strong> {props.numero_socio} <br />
                            <strong>Data de Nascimento:</strong> {props.birthdate} <br />
                            <strong>Data de Validade:</strong> {props.expiryDate}
                        </CardText>
                    </Col>
                </Row>
            </Col>
        </Card>
    );
}

export default Carteira;
