import axios from "axios";

const api = axios.create({
	baseURL: 'https://app.sinpolsergipe.org.br/api/?/',
	//baseURL: 'http://localhost/backendsindicato/?/',
	//withCredentials: false,
	headers: {
        'Content-Type': 'multipart/form-data'
      }
});

export const openApi = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL
});

export default api;
