import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import defaultService from "services/defaultService";

const Avatar = ({ handleUpload }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [image, setImage] = useState(user.avatar);
  const [editar, setEditar] = useState(false);

  // Evento de upload de foto
  const handleChange = async (event) => {
    // Obtém o arquivo selecionado
    const file = event.target.files[0];

    // Converte o arquivo em um objeto Blob
    //const blob = new Blob([file], { type: file.type });

    const form = {
        table: user?.tipo_acesso == "Admin" ? "administradores" : "socios",
        image: file
    }

    const resp = await defaultService.updateAvatar(user.id, form);

    setImage(resp.data);
    setEditar(false);

    user.avatar = resp.data;
    localStorage.setItem("user", JSON.stringify(user));
  };

  const onClickImage = () => {
    document.getElementById("image").click();
  };

  return (
    <Row className="justify-content-center aling-items-center ml-2">
      <Col xs="12" md="12" sm="12">
          <img
            src={'https://app.sinpolsergipe.org.br/api/uploads/avatars/' + image }
            width="100"
            height="100"
            rounded="circle"
            style={{ objectFit: "contain", backgroundColor: 'grey', borderRadius: 150, cursor: 'pointer' }}
            onClick={() => setEditar(!editar)}
          />
          <Button color="primary" size="sm" onClick={() => onClickImage()}>
            <i className="fas fa-camera"></i> Alterar Foto
          </Button>
      </Col>
          <input type="file" style={{ display: 'none' }} name="image" id="image" onChange={handleChange} />
    </Row>
  );
};

export default Avatar;