
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import { AuthProvider } from 'contexts/AuthContext';

import AdminLayout from "layouts/Admin.js";
import Login from "views/Login";
import LoginAdmin from "views/LoginAdmin";
import CadastroSociosScreen from "views/CadastroSociosScreen";
import RecuperarSenhaScreen from "views/RecuperarSenhaScreen";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthProvider>
    <BrowserRouter>
      <Routes>
        
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/login-admin" element={<LoginAdmin />} />
        <Route path="/login/" element={<Login />} />
        <Route path="/cadastrar/" element={<CadastroSociosScreen />} />
        <Route path="/recuperar-senha/" element={<RecuperarSenhaScreen />} />
        <Route path="/" element={/*<Navigate to="/admin/dashboard" replace />*/<Login />} />
      </Routes>
    </BrowserRouter>
  </AuthProvider>
);
